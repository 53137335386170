import classNames from 'classnames'
import React from 'react'
import { Modal } from 'react-bootstrap'

const ModalAlert = ({ modal, toggleModal,bgColor ,children}:{ modal: boolean, bgColor:string,toggleModal: () => void,children: React.ReactNode}) => {
  return (
    <Modal show={modal} onHide={toggleModal} size="sm" >
    <div className={classNames("modal-dialog-centered", "bg-"+ bgColor)}>
      <Modal.Body className="p-4">
       {children}
      </Modal.Body>
    </div>
  </Modal>
  )
}

export default ModalAlert