import React from "react";

/**
 * Renders the preloader
 */
const Loader = () => {
  return (
    <div className="preloader position-fixed" id="preloader" style={{ opacity: 0.5 }}>
      <div className="status" id="status">
        <div className="spinner" />
        <h1>HoldOn...</h1>
      </div>
    </div>
  );
};

export default Loader;
