// apicore
import { NotificationItem } from "../../layouts/Topbar";

// constants
import { NotificationActionTypes } from "./constants";

const INIT_STATE = {
    latestNotifications: [],
    counts: 0,
};

interface NotificationAction {
    type: NotificationActionTypes.SET_NOTIFICATIONS | NotificationActionTypes.SET_ONLY_NOTIFICATONS
    payload: {
        data?: NotificationItem[];
    };
}
interface State {
    latestNotifications: NotificationItem[];
    counts: number;
}

const Notifications = (state: State = INIT_STATE, action: NotificationAction): any => {
    switch (action.type) {
        case NotificationActionTypes.SET_NOTIFICATIONS:
            const latestNotifications = [...state.latestNotifications, action.payload.data] as Array<NotificationItem>;
            state.latestNotifications = latestNotifications
            state.counts = latestNotifications.length
            return { ...state }

        case NotificationActionTypes.SET_ONLY_NOTIFICATONS:
            state.latestNotifications = [...state.latestNotifications, action.payload.data] as Array<NotificationItem>
            return { ...state }
        default:
            return { ...state };
    }
};

export default Notifications;
