import React from "react";
import { Modal } from "react-bootstrap";
import classNames from "classnames";
interface ModalProps {
  className?: string,
  modal: boolean,
  toggle: () => void,
  children: React.ReactNode
  header?: React.ReactElement
  size?: { size: "sm" | "lg" | "xl" }
  align?: keyof typeof ModalPositions
  backdrop?: any
}
const ModalPositions = {
  "top": "modal-top",
  "right": "modal-right",
  "bottom": "modal-bottom",
  "center": "modal-dialog-centered",
}
const MyModal = ({ className, modal, toggle, children, header, size, align = "top", backdrop }: ModalProps) => {
  const position = ModalPositions[align]

  return (
    <Modal
      show={modal}
      onHide={toggle}
      dialogClassName={classNames(" ", position)}
      {...size}
      {...backdrop}
    // keyboard={false}
    >
      {header && (
        <Modal.Header onHide={toggle} closeButton>
          {header}
        </Modal.Header>
      )}

      <Modal.Body className="p-4">
        {children}
      </Modal.Body>
    </Modal >

  )
}

export default MyModal