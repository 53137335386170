import { APICore } from "./apiCore";

const api = new APICore();

// account
async function login(params: { username: string; password: string }) {
  const baseUrl = "/driver/api/auth/public/auth/login"; 
  const data = {
    phone: params.username,
    password: params.password,
  }
  return await api.create(`${baseUrl}`, data);
}

function logout() {
  const baseUrl = "/api/auth/public/auth/logout";
  return api.create(`${baseUrl}`, {});
}
 
function forgotPassword(params: { username: string }) {
  const baseUrl = "/forget-password/";
  return api.create(`${baseUrl}`, params);
}

export { login, logout,  forgotPassword };
