import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
} from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";

// components
import PageTitle from "../../../components/PageTitle";

import { FormInput } from "../../../components";

import ApiHandlers from "../../../helpers/api/handlers";
import { useToast } from "../../../hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import MyModal from "../../../components/shared/Modal";
import ModalAlert from "../../../components/shared/ModalAlert";
import { useQueries } from "@tanstack/react-query";
import { store } from "../../../redux/store";
import { setLoading } from "../../../redux/actions";


interface OptionTypes {
  id: number | string;
  value: string;
  label: string;
}

const ModalComponent = ({ submissionId, CloseOpenedNextModal, resetPage }: { submissionId: string, CloseOpenedNextModal: () => void, resetPage: () => void }) => {
  const [otp, setOtp] = useState<string | null>(null)
  const [modal, showModal] = useState<boolean>(false)

  const { toast } = useToast()
  const dispatch = useDispatch()
  const SubmitSubmissionData = async () => {
    dispatch(setLoading(true))
    if (otp === null) {
    dispatch(setLoading(false))

      return toast({ title: "OTP is required Before Submit" })
    }
    const { data } = await ApiHandlers.markOrderAsCompleted({
      submissionId,
      otp
    })
    if (!data.success) {
    dispatch(setLoading(false))

      return toast({ title: "Something Went Wrong", description: data.message })
    }
    dispatch(setLoading(false))
    CloseOpenedNextModal()
    toggleMyAlertModal()
    resetPage()
  }
  const toggleMyAlertModal = async () => showModal(!modal)
  return (
    <React.Fragment>
      <Row>
        <h3 className="text-dark">Please Enter OTP </h3>
        <Col >
          <FormInput
            name="otp"           
            placeholder="Enter six digit OTP"
            containerClass={"mb-3"}
            maxLength={6}
            minLength={6}
            required
            onChange={(e) => setOtp(e.target.value)}

          />
        </Col>
        <Button
          variant="primary"
          onClick={SubmitSubmissionData}
          className="waves-effect waves-light m-1"
        >
          Submit
        </Button>
      </Row>
      <ModalAlert modal={modal} toggleModal={toggleMyAlertModal} bgColor="success" >
        <div className="text-center">
          <i className="dripicons-checkmark h1 text-white"></i>         
          <p className="mt-3 text-white">
            This order has been marked as PAID and completed
          </p>
          <button
            type="button"
            onClick={toggleMyAlertModal}
            className="btn btn-light my-2"
          >
            OK
          </button>
        </div>
      </ModalAlert>
    </React.Fragment>
  )
}
const ProjectForm = () => {
  const { toast } = useToast()
  const dispatch  = useDispatch()
  const { aid } = useSelector((state: any) => state.Auth.user)
  const [modal, showModal] = useState<boolean>(false)
  const [amount, setAmount] = useState<string | number>(0)
  const [singleSelections, setSingleSelections] = useState<OptionTypes[]>([]);
  const [showUserDetails, setShowUserDetails] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<Array<AssignedRecords> | null>(null);
  const [allOrders, setAllOrders] = useState<OptionTypes[] | null>(null)
  const [bottlesQty, setBottlesQty] = useState<string | number>(0)
  const [nextModal, setNextModal] = useState<boolean>(false);

  const methods = useForm();
  const {
    register,
    control,
    formState: { errors },
  } = methods;
  let currentValue: any
  const onChangeSingleSelection = (selected: OptionTypes[]) => setSingleSelections(selected);
  const fetchAllSubmissions = React.useCallback(async () => {
    const response = await ApiHandlers.getDriverSubmissionsAssignedToHim(aid)
    if (!response.data.success) {
      return toast({ title: "Something went wrong", description: response.data.message, })
    }
    setUserDetails(response.data.result)
    const validatedData = response.data.result.map((submission: { id: string, orderId: string }) => {
      return {
        value: submission.id,
        label: submission.orderId
      }
    })
    setAllOrders(validatedData)
    return validatedData
  },[aid])
  const query = useQueries({
    queries: [
      { queryKey: ['getAllSubmissions', 1], queryFn: fetchAllSubmissions, refetchInterval: 10000 },
      { queryKey: ['getDefaultAmount', 2], queryFn: ApiHandlers.getDeafultAmount,  refetchInterval: 10000 }
    ]
  })
  const getDefaultAmount = query[1].data?.data?.result[0]
  const OnChangeOfBottleNumbers = (bottles: string) => {
    if (Number(bottles) < 0) {
      toast({ title: "Bottle Quantity cannot be 0 or lower than 0" })
      return
    }
    const calculate = Number(getDefaultAmount.deafultAmount) * Number(bottles)
    setAmount(calculate)
    setBottlesQty(bottles)
  }
  const toggleModal = () => {
    if (Number(bottlesQty) === 0 || bottlesQty === "") {
      return toast({ title: "Please Enter Bottle Quantity", description: "Bottle Quantity cannot be 0 or empty" })
    }
   
    showModal(!modal)
  }
  const toggleNextModal = async () => {
    toast({ title: "Sending OTP", description:"Please wait"  })
    dispatch(setLoading(true))
    const { data } = await ApiHandlers.readyForPayouts({
      submissionId: singleSelections[0].value,
      amount: amount,
      bottleQty: bottlesQty
    })
    if (!data.success) {
      dispatch(setLoading(false))
      return toast({ title: "Something went wrong", description: data.message, })
    }
    showModal(false)
    toast({ title: "OTP Sent Successfully" })
    dispatch(setLoading(false))
    setNextModal(!nextModal);
  }
  const CloseOpenedNextModal = async () => setNextModal(false);
  const refreshData = () => {
    query[0].refetch()
    query[1].refetch()
  }
  const resetPage = () => {
    setSingleSelections([])
    setAmount(0)
    setBottlesQty(0)
    setShowUserDetails(false)
    refreshData()
    
  }
  const maskedDetails = (value: string) => {
    let maskedData: string = ""
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Check if the input is a valid mobile number
    if (typeof value !== 'string' || value.length === 10 || /^\d+$/.test(value)) {
      maskedData = '*****' + value.slice(5);
      return maskedData
    }
    if (typeof value === 'string' || emailRegex.test(value)) {
      const [localPart, domainPart] = value.split('@');
      const maskedLocalPart = '*'.repeat(Math.ceil(localPart.length / 2)) + localPart.slice(Math.ceil(localPart.length / 2));
      maskedData = maskedLocalPart + '@' + domainPart;
      return maskedData
    }

    return maskedData;
  }
  function handleChange() {
    let previousValue = currentValue
   
    currentValue = store.getState().Notifications.latestNotifications.length
    
    if (previousValue !== undefined && previousValue !== currentValue) {
      refreshData()
    }
    unsubscribe()
  }
  const unsubscribe = store.subscribe(handleChange)
  return (
    <>


      <PageTitle
        breadCrumbItems={[

          {
            label: "Orders Details",
            path: "/apps/orders",
            active: true,
          },
        ]}
        title={"Orders Details"}
      />
      <Row>
        <div className="alert alert-info alert-dismissible fade show" role="alert">
          You have been assigned <strong>{allOrders?.length}</strong> New Pickup Orders

        </div>

      </Row>
      {query[1].data?.data?.result.length === 0 ?
        <div className="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>There is Bottle Amount setted. Please Contact to Administrator</strong>
        </div> :
        <Row>
          <Col xl={12}>
            <Card>

              <Card.Body>
                <h4 className="header-title">Search From Order ID</h4>
                <Typeahead
                  id="select2"
                  labelKey={"label"}
                  multiple={false}
                  onChange={onChangeSingleSelection}
                  options={allOrders !== null ? allOrders : []}
                  placeholder={"Enter Order ID"}
                  selected={singleSelections}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      }
      {
        singleSelections.length > 0 && query[1].isFetched && query[1].isSuccess &&
        <Row>
          <Col>
            <Card>
              <Card.Body>

                <Row>
                  <Col xl={6}>
                    <FormInput
                      name="amount"
                      label="Default Amount"
                      containerClass={"mb-3"}
                      register={register}
                      key="name"
                      errors={errors}
                      control={control}
                      defaultValue={getDefaultAmount.deafultAmount}
                      disabled
                    />
                  </Col>
                  <Col xl={6}>
                    <FormInput
                      name="numberOfBottles"
                      label="Number Of Bottles"
                      placeholder="Enter Number Of Bottles"
                      type="number"
                      onChange={(e: any) => OnChangeOfBottleNumbers(e.target.value)}
                      containerClass={"mb-3"}
                      register={register}
                      key="numberOfBottles"
                      defaultValue={bottlesQty}
                      errors={errors}
                      control={control}
                    />
                  </Col >
                  {showUserDetails && userDetails && userDetails[0].user && (
                    <Col lg={12} className="text-center">
                      <h5 className="fw-bold text-warning">User Full Name : {(userDetails[0]?.user && userDetails?.filter((item: any) => item.orderId === singleSelections[0]?.label)[0])?.user?.fullname}</h5>
                      <h5 className="fw-bold text-warning">User Email : {maskedDetails((userDetails[0]?.user && userDetails?.filter((item: any) => item.orderId === singleSelections[0]?.label)[0])?.user?.email)}</h5>
                    </Col>
                  )}

                  <Col className="text-center" lg={12}>
                  <h4 className="fw-bold text-warning">User Phone : {(userDetails && userDetails[0].user &&(userDetails[0]?.user && userDetails?.filter((item: any) => item.orderId === singleSelections[0]?.label)[0])?.user?.phone)}</h4>

                  <h4 className="fw-bold text-dark">Pickup Address : { (userDetails && userDetails[0].user && userDetails[0]?.user && userDetails?.filter((item: any) => item.orderId === singleSelections[0]?.label)[0])?.user?.address}</h4>

                    <h5 className="fw-bold text-success">Total Payout:{getDefaultAmount.symbol} {Number(amount).toFixed(2)}</h5>
                    <small>
                      This amount will in Dollars ({getDefaultAmount.currency})
                    </small>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col className="text-center">
                    <Button
                      onClick={() => setSingleSelections([])}
                      variant="light"
                      className="waves-effect waves-light m-1"
                    >
                      <i className="fe-x me-1"></i> Cancel
                    </Button>
                    <Button
                      onClick={toggleModal}
                      variant="success"
                      className="waves-effect waves-light m-1"
                    >
                      <i className="fe-check-circle me-1"></i> Proceed To Pay
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => setShowUserDetails(!showUserDetails)}
                      className="waves-effect waves-light m-1"
                    >
                      <i className="fe-user-check me-1"></i>Verify User Details
                    </Button>

                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>
          <Modal dialogClassName="modal-dialog-centered" show={modal}  backdrop="static" onHide={toggleModal}>
            <Modal.Header closeButton>
              <h4 className="modal-title"> Procceed To Complete This Order</h4>
            </Modal.Header>
            <Modal.Body>
              <h4>OTP will sent to customer, on moving to next step</h4>
              <p className="text-dark">To Mark as Complete this order, please Enter OTP provided by the User on Further Step</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={toggleNextModal}>
                Next
              </Button>
            </Modal.Footer>
          </Modal>
          <MyModal backdrop={{ backdrop: "static" }} modal={nextModal} toggle={()=> setNextModal(!nextModal)} align="center"  header={<h5 className="text-primary fw-bold">Complete This Order</h5>}>
            <ModalComponent resetPage={resetPage} submissionId={singleSelections[0]?.value} CloseOpenedNextModal={CloseOpenedNextModal} />
          </MyModal>

        </Row>
      }

    </>
  );
};

export default ProjectForm;
export interface AssignedRecords {
  id: string
  orderId: string
  user: User
}

export interface User {
  id: string
  fullname: string
  phone: string
  address: string
  email: string
}