import React from "react";
import { Route, Navigate, RouteProps } from "react-router-dom";

// components
import PrivateRoute from "./PrivateRoute";
import ProjectForm from "../pages/apps/Projects/DriverForm";
// import Root from './Root';

// lazy load all the views
// auth
const Logout = React.lazy(() => import("../pages/auth/Logout"));
const DriverLogin = React.lazy(() => import("../pages/auth/DriverLogin"));



const Error404 = React.lazy(() => import("../pages/error/Error404"));
const Error500 = React.lazy(() => import("../pages/error/Error500"));
const Maintenance = React.lazy(() => import("../pages/error/Maintenance"));

export interface RoutesProps {
  path: RouteProps["path"];
  name?: string;
  element?: RouteProps["element"];
  route?: any;
  exact?: boolean;
  icon?: string;
  header?: string;
  roles?: string[];
  children?: RoutesProps[];
}

const dashboardRoutes: RoutesProps = {
  path: "/dashboard",
  name: "Dashboard",
  icon: "airplay",
  header: "Navigation",
  roles: ["Driver"],
  children: [
    {
      path: "/",
      name: "Root",
      element: <Navigate to="/home" />,
      route: PrivateRoute,
    },
    {
      path: "/home",
      name: "Dashboard",
      element: <ProjectForm />,
      route: PrivateRoute,
    },

  ],
};










// auth
const authRoutes: RoutesProps[] = [

  {
    path: "/auth/login",
    name: "Driver Login",
    element: <DriverLogin />,
    route: Route,
  },
  {
    path: "/auth/logout",
    name: "Logout",
    element: <Logout />,
    route: Route,
  },


];

// public routes
const otherPublicRoutes = [
  {
    path: "/maintenance",
    name: "Maintenance",
    element: <Maintenance />,
    route: Route,
  },
  {
    path: "/error-404",
    name: "Error - 404",
    element: <Error404 />,
    route: Route,
  },

  {
    path: "/error-500",
    name: "Error - 500",
    element: <Error500 />,
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
  let flatRoutes: RoutesProps[] = [];

  routes = routes || [];
  routes.forEach((item: RoutesProps) => {
    flatRoutes.push(item);

    if (typeof item.children !== "undefined") {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};
// All routes
const authProtectedRoutes = [
  dashboardRoutes,


];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
