// constants
import { NotificationActionTypes } from "./constants";

export interface NotificationActionType {
  type: NotificationActionTypes.SET_NOTIFICATIONS | NotificationActionTypes.SET_ONLY_NOTIFICATONS,

  payload: {} | string;
}

export const addNewNotification = (data: any): NotificationActionType => ({
  type: NotificationActionTypes.SET_NOTIFICATIONS,
  payload: { data },
});
export const addOnlyNotification = (data: any): NotificationActionType => ({
  type: NotificationActionTypes.SET_ONLY_NOTIFICATONS,
  payload: { data },
});
export const FetchNotifications = (): NotificationActionType => ({
  type: NotificationActionTypes.SET_NOTIFICATIONS,
  payload: {},
});