import { SOCKET_EVENTS } from "../constants";
import { socket } from "./socket";
import { GetLatestNotfications, OnConnect, OnServerClose, onDisconnect } from "./socketHandlers";


export function SocketListeners() {

    socket.on('connect', OnConnect);
    socket.on('disconnect', onDisconnect);
    socket.on("connect_error", onDisconnect);
    socket.on(SOCKET_EVENTS.GetNotifications, GetLatestNotfications);
    socket.on(SOCKET_EVENTS.ServerClosed, OnServerClose);
}
export function CleanUp() {
    socket.off('connect', OnConnect);
    socket.off('disconnect', onDisconnect);
    socket.off("connect_error", onDisconnect);
    socket.off(SOCKET_EVENTS.GetNotifications, GetLatestNotfications);
    socket.off(SOCKET_EVENTS.ServerClosed, OnServerClose);
}
