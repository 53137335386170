import { APICore } from "./apiCore"
const api = new APICore();
class Handlers {

    /** DRIVER API ROUTES */
    loginDriver(data: any) {
        return api.create("/driver/api/auth/public/auth/login", data)
    }
    updateDriverPassword(data: any) {
        return api.updatePatch("/driver/api/auth/public/auth/update-password", data)
    }

    // Driver API ROUTES
    getDriverSubmissionsAssignedToHim(driverId: string, user: boolean = true) {
        return api.get("/driver/api/public/my-submissions", { driverId, user })

    }
    readyForPayouts(data: {
        submissionId: string,
        amount: string | number,
        bottleQty: string | number
    }) {
        return api.create("/driver/api/public/ready-for-payout", data)

    }
    getDeafultAmount() {
        return api.get("/api/public/amount", null)
    }
    markOrderAsCompleted(data: {
        submissionId: string,
        otp: string,
    }) {
        return api.create("/driver/api/public/complete-order", data)
    }
}
const ApiHandlers = new Handlers()
export default ApiHandlers